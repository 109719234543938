<template>
    <div class="absolute bottom-0 flex items-center justify-center left-0 right-0 text-6xl top-0 z-50">
        <i class="fas fa-circle-notch fa-spin fs-1"></i>
    </div>
</template>

<script>
    export default {
        name: 'LocalLoader',
    }
</script>
<style scoped>
    .fa-circle-notch{
        color:black;
    }
</style>